var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-row',[_c('v-card-text',{staticStyle:{"background":"white","height":"100%"}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',[[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.orders,"search":_vm.search,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading":_vm.loading,"loading-text":"Please wait...","item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',{staticStyle:{"font-size":"26px"}},[_vm._v(" All Orders "),_c('v-spacer'),_c('div',{staticClass:"pr-5 pb-5 pt-2"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('b',[_vm._v("Order Refrence: ")]),_vm._v(" "+_vm._s(item.id)+" "),_c('br'),_c('b',[_vm._v("User ID : ")]),_vm._v(" "+_vm._s(item.user_id)),_c('br'),_c('b',[_vm._v("Date : ")]),_vm._v(" "+_vm._s(item.ordered_date)),_c('br'),_c('b',[_vm._v("Host Site : ")]),_vm._v(" "+_vm._s(item.host)),_c('br'),_c('b',[_vm._v("Order Status : ")]),_vm._v(" "+_vm._s(item.order_status)),_c('br'),_c('b',[_vm._v("Delivery Type : ")]),_vm._v(" "+_vm._s(item.delivery_status)),_c('br'),_c('b',[_vm._v("Copun Code : ")]),_vm._v(" "+_vm._s(item.coupon_code)),_c('br'),_c('b',[_vm._v("Copun Percentage : ")]),_vm._v(" "+_vm._s(item.coupon_percentage)),_c('br'),_c('b',[_vm._v("Total Point : ")]),_vm._v(" "+_vm._s(item.point_total)),_c('br')])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.admin_status == 'Confirmed')?_c('v-btn',{staticStyle:{"padding-left":"0px !important"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.viewInvoice(item)}}},[_vm._v("Invoice")]):_vm._e()]}},{key:"item.motherinvoice",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.viewMotherInvoice(item)}}},[_c('v-icon',{staticClass:"pr-5",staticStyle:{"margin-left":"0 !important"},attrs:{"dark":""}},[_vm._v(" mdi-eye-outline")]),_vm._v(" "+_vm._s(item.reference_id)+" ")],1)]}},{key:"item.admin_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.admin_status),"dark":""}},[_vm._v(" "+_vm._s(item.admin_status)+" ")])]}}])})],1)]],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
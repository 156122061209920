<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-card>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="orders"
                    :search="search"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    :loading="loading"
                    loading-text="Please wait..."
                    item-key="id"
                    show-expand
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-card-title style="font-size: 26px">
                        All Orders
                        <v-spacer></v-spacer>
                        <div class="pr-5 pb-5 pt-2" >
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            filled
                            rounded
                            dense
                            
                            hide-details
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <b>Order Refrence: </b> {{ item.id }} <br />
                        <b>User ID : </b> {{ item.user_id }}<br />
                        <b>Date : </b> {{ item.ordered_date }}<br />
                        <b>Host Site : </b> {{ item.host }}<br />
                        <b>Order Status : </b> {{ item.order_status }}<br />
                        <b>Delivery Type : </b> {{ item.delivery_status }}<br />
                        <b>Copun Code : </b> {{ item.coupon_code }}<br />
                        <b>Copun Percentage : </b> {{ item.coupon_percentage
                        }}<br />
                        <b>Total Point : </b> {{ item.point_total }}<br />
                      </td>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        v-if="item.admin_status == 'Confirmed'"
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewInvoice(item)"
                        >Invoice</v-btn
                      >
                    </template>

                    <template v-slot:item.motherinvoice="{ item }">
                      <v-btn @click="viewMotherInvoice(item)" text>
                        <v-icon
                          dark
                          class="pr-5"
                          style="margin-left: 0 !important"
                        >
                          mdi-eye-outline</v-icon
                        >
                        {{ item.reference_id }}
                      </v-btn>
                    </template>

                    <template v-slot:item.admin_status="{ item }">
                      <v-chip :color="getColor(item.admin_status)" dark>
                        {{ item.admin_status }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
          </v-row>
        </v-card-text></v-row
      ></v-card
    ></v-container
  >
</template>


<script>
export default {
  data: () => ({
    expanded: [],
    search: "",
    singleExpand: false,
    loading: true,
    dessertHeaders: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Invoice ID", value: "invoice_id", sortable: false },
      { text: "Date", value: "ordered_date", sortable: false },
      { text: "Price ", value: "price_total", sortable: false },
      { text: "Order Status", value: "order_status", sortable: false },
    //   { text: "Admin Status", value: "admin_status", sortable: false },
    //   { text: "Delivery Status", value: "delivery_status", sortable: false },
    //   { text: "View", value: "actions", sortable: false },
    //   { text: "Ref.No", value: "motherinvoice", sortable: false },
      // { text: 'test', value: 'tes' },
      { text: "", value: "data-table-expand" },
    ],

    showItem: {},

    tickets: [],

    orders: [],
    show: false,
    today_order: 0,
    total_customer: 0,
    total_seller: 0,
    total_staff: 0,

    series: [44, 55, 13],
    chartOptions: {
      chart: {
        width: 280,
        type: "pie",
        offsetX: 60,
      },
      labels: ["Delivered", "Pending", "Cancelled"],
      colors: ["#4CAF50", "#EFCE62", "#F94B4B"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      legend: {
        position: "left",
        offsetY: 25,
        offsetX: -30,
        height: 630,
        itemMargin: {
          vertical: 20,
        },
      },
    },
  }),

  methods: {
    initialize() {
      axios.get("Cart/pos_orders/").then((response) => {
        this.orders = response.data.data;
        this.loading = false;
        // console.log("this is order", this.orders);
      });

      axios.get("supports/dashboard/").then((response) => {
        this.today_order = response.data.data.orders;
        this.total_customer = response.data.data.total_customers;
        this.total_seller = response.data.data.total_sellers;
        this.total_staff = response.data.data.total_staff;
      });
    },
    viewInvoice(item) {
      if (item.reference_id == 0) {
        this.$router.push({
          path: "/dashboard/pages/orderinvoice_updated",
          query: { id: item.invoice_id },
        });
        // } else {
        //   this.$router.push({
        //     path: "/dashboard/pages/new_invoice",
        //     query: { id: item.invoice_id },
        //   });
      } else {
        this.$router.push({
          path: "/dashboard/pages/new_invoiceUpdated",
          query: { id: item.invoice_id },
        });
      }
    },

    viewMotherInvoice(item) {
      // console.log("I am clicked" , item)

      if (item.reference_id == 0) {
        this.$router.push({
          path: "/dashboard/pages/orderinvoice_updated",
          query: { id: item.invoice_id },
        });
      } else {
        // console.log("clicked");
        this.$router.push({
          path: "/dashboard/pages/motherinvoice_updated",
          query: { id: item.reference_id },
        });
      }
    },

    getColor(admin_status) {
      if (admin_status == "Cancelled") return "red";
      else return "green";
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>